import { SET_CONTACT_PAGE } from "../common/action-names";
import { fetchContactPageDetails } from "../services/contact";

export const setContactDetails = (payload, dispatch) => {
  dispatch({
    type: SET_CONTACT_PAGE,
    payload,
  });
};

export const getContactDetails = async (dispatch) => {
  try {
    const contact = await fetchContactPageDetails();
    setContactDetails(contact, dispatch);
    console.log(contact);
  } catch (e) {
    console.log("error", e);
  }
};
