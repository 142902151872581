import {
  SET_HOME_CHOOSE_US,
  SET_HOME_CLIENT,
  SET_HOME_FOOTER,
  SET_HOME_MAIN,
  SET_HOME_METHOD,
  SET_HOME_SERVICE,
} from "../common/action-names";

export const home = {
  main: {},
  service: {},
  methodology: {},
  chooseUs: {},
  client: {},
  footer: {},
};

export function homeReducer(state = home, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_HOME_MAIN:
      return {
        ...state,
        home: {
          ...state.home,
          main: { ...payload },
        },
      };
    case SET_HOME_SERVICE:
      return {
        ...state,
        home: {
          ...state.home,
          service: { ...payload },
        },
      };
    case SET_HOME_CHOOSE_US:
      return {
        ...state,
        home: {
          ...state.home,
          chooseUs: { ...payload },
        },
      };
    case SET_HOME_CLIENT:
      return {
        ...state,
        home: {
          ...state.home,
          client: { ...payload },
        },
      };
    case SET_HOME_METHOD:
      return {
        ...state,
        home: {
          ...state.home,
          methodology: { ...payload },
        },
      };
    case SET_HOME_FOOTER:
      return {
        ...state,
        home: {
          ...state.home,
          footer: { ...payload },
        },
      };
    default:
      return state;
  }
}
