import { SET_ABOUT_PAGE, SET_CONTACT_PAGE } from "../common/action-names";

export const about = {
  about: null,
};

export function aboutReducer(state = about, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ABOUT_PAGE:
      return {
        ...state,
        about: { ...payload },
      };
    default:
      return state;
  }
}
