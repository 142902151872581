export const getLang = (state, key) => {
  const langs = state?.common?.languages;
  if (langs && langs[key]) {
    return langs[key];
  }
  return "";
};

export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const parseFiles = (files) => {
  try {
    if (files) {
      return JSON.parse(files?.files);
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const getServicesById = (state, serviceId, key) => {
  try {
    if (state[serviceId][key]) {
      return state[serviceId][key];
    }
    return "";
  } catch (e) {
    return "";
  }
};

export const getMeta = (metaName) => {
  const metas = document.getElementsByTagName("meta");
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i].getAttribute("content");
    }
  }
  return "";
};

export const getCookie = (name) => {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const setCookie = (json) => {
  let cookie = "";
  console.log("json", json);
  for (const key in json) {
    cookie += `${key}=${json[key]};`;
  }
  document.cookie = `${cookie}; path=/; max-age=${60 * 60 * 24 * 14};`;
  console.log(cookie);
};
