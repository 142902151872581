import fb from "../assets/images/social-icon/Facebook.png"
import insta from "../assets/images/social-icon/Instagram.png"
import linkedin from "../assets/images/social-icon/LinkedIn.png"
import aditya from "../assets/images/team/aditya.png"
import alokKumar from "../assets/images/team/alok-kumar.png"
import harshita from "../assets/images/team/harshita.png"
// import krunali from "../assets/images/team/krunali.png"
import pankajKumar from "../assets/images/team/pankaj-kumar.png"
import Piyush from "../assets/images/team/piyush.png"
import pranjal from "../assets/images/team/pranjal.png"



export default {
  "head": "About Swifsys",
  "desc": "As a multifaceted Digital marketing and software solution agency we cater to your every need. We provide Value to your future. It's in our DNA.",
  "subHead": "Swifsys Software: Digital Marketing and Software Solution Agency",
  "subDesc": "Swifsys Software is a Digital Marketing and software solution agency based in Lucknow, India. Our goal is quite simple: it's to make your business grow with our expertise in analysis, development, and design, we create unique and personalized digital marketing solutions for each client, 100% achievable with a return on investment. <br><br>We want to develop an agency that clients get excited to be pitched to and that they value working with.",
  "teams": {
    "head": "We are team Swifsian",
    "desc": "Our motto is: Organise complexity, without complications.",
    "subHead": "Meet the Chosen Ones that make it happen",
    "emps": [
      {
        "name": "Piyush Rastogi",
        "img": Piyush,
        "role": "CEO",
        "nick": "",
        "fb": {
          "icon": fb,
          "link":""
        },
        "ln": {
          "icon": linkedin,
          "link":""
        },
        "in": {
          "icon": insta,
          "link":""
        }
      },
     
      // {
      //   "name": "Mohit Prakash",
      //   "img": "https://www.swifsys.com/proxy-img.php/about/mohit.png",
      //   "role": "Co-Founder",
      //   "nick": "cyborg",
      //   "fb": {
      //     "icon": fb,
      //     "link":""
      //   },
      //   "ln": {
      //     "icon": linkedin,
      //     "link":""
      //   },
      //   "in": {
      //     "icon": insta,
      //     "link":""
      //   }
      // },
      {
        "name": "Alok Kumar",
        "img": alokKumar,
        "role": "Vice President",
        "nick": "",
        "fb": {
          "icon": fb,
          "link":""
        },
        "ln": {
          "icon": linkedin,
          "link":""
        },
        "in": {
          "icon": insta,
          "link":""
        }
      },
      {
        "name": "Harshita Varma",
        "img": harshita,
        "role": "HR (Human Resource)",
        "nick": "",
        "fb": {
          "icon": fb,
          "link":""
        },
        "ln": {
          "icon": linkedin,
          "link":""
        },
        "in": {
          "icon": insta,
          "link":""
        }
      },
      {
        "name": "Pranjal Singh",
        "img": pranjal,
        "role": "Social Media Manager",
        "nick": "",
        "fb": {
          "icon": fb,
          "link":""
        },
        "ln": {
          "icon": linkedin,
          "link":""
        },
        "in": {
          "icon": insta,
          "link":""
        }
      },
      {
        "name": "Aditya Singh",
        "img": aditya,
        "role": "Copy Writer Manager",
        "nick": "",
        "fb": {
          "icon": fb,
          "link":""
        },
        "ln": {
          "icon": linkedin,
          "link":""
        },
        "in": {
          "icon": insta,
          "link":""
        }
      },
      {
        "name": "Pankaj Kumar",
        "img": pankajKumar,
        "role": "Graphic Designer",
        "nick": "",
        "fb": {
          "icon": fb,
          "link":""
        },
        "ln": {
          "icon": linkedin,
          "link":""
        },
        "in": {
          "icon": insta,
          "link":""
        }
      },
      // {
      //   "name": "Krunali Raut",
      //   "img": krunali,
      //   "role": "Software Developer",
      //   "nick": "",
      //   "fb": {
      //     "icon": fb,
      //     "link":""
      //   },
      //   "ln": {
      //     "icon": linkedin,
      //     "link":"https://www.linkedin.com/in/krunali-raut/"
      //   },
      //   "in": {
      //     "icon": insta,
      //     "link":""
      //   }
      // }
    ]
  }
}
