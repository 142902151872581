import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import Header from "./components/Header";
import styled from "@emotion/styled";
import {
  AboutLoader,
  AddUpdateCourseLoader,
  BlogsListLoadableLoader,
  ContactLoader,
  EmployeeLoader,
  FooterLoader,
  HomeLoader,
  NewsLetterLoadable,
  ServiceLoader,
  SMSLoader,
  TestLoader,
  TrainingLoader,
} from "./Loadable";
import route from "./common/route";
import { useCallback, useContext, useEffect } from "react";
import { Store } from "./store/store";
import { getContactDetails } from "./actions/contact";
import { getAboutDetails } from "./actions/about";
import {
  getHomeServices,
  getHomeChooseUs,
  getHomeMethod,
  getHomeClient,
  getHomeFooter,
  getHomeBanner,
} from "./actions/home";
import ScrollToTop from "./ScrollToTop";
import Floater from "./components/Floater";
const MainContainer = styled.main`
  width: 100%;
  margin: auto;
  overflow-x: hidden;
`;
function App(props) {
  const { dispatch } = useContext(Store);
  const getAllData = useCallback(() => {
    if (!route.noHeaderRoute.includes(window.location.pathname)) {
      getHomeBanner(dispatch);
      getHomeServices(dispatch);
      getHomeChooseUs(dispatch);
      getHomeMethod(dispatch);
      getHomeClient(dispatch);
      getHomeFooter(dispatch);
      getContactDetails(dispatch);
      getAboutDetails(dispatch);
    }
  }, []);
  useEffect(() => {
    getAllData();
    console.log("router", props);
  }, [dispatch]);
  return (
    <MainContainer>
      <Router>
        <ScrollToTop>
          {!route.noHeaderRoute.includes(window.location.pathname) && (
            <Header />
          )}
          <Switch>
            <Route path={route.base} component={HomeLoader} exact />
            <Route path={route.home} component={HomeLoader} />
            <Route path={route.contactUs} component={ContactLoader} />
            <Route path={route.aboutUs} component={AboutLoader} />
            <Route path={route.service} component={ServiceLoader} />
            <Route path={route.sms} component={SMSLoader} />
            <Route path={route.training} component={TrainingLoader} />
            <Route path={route.test} component={TestLoader} />
            <Route
              path={route.addUpdateCourse}
              component={AddUpdateCourseLoader}
            />
            <Route path={route.blogsList} component={BlogsListLoadableLoader} />
            <Route path={route.employee} component={EmployeeLoader} />
            <Route path={route.subService} component={ServiceLoader} />
          </Switch>
          {!route.noFooterRoute.includes(window.location.pathname) && (
            <NewsLetterLoadable />
          )}
          {!route.noFooterRoute.includes(window.location.pathname) && (
            <FooterLoader />
          )}
        </ScrollToTop>
      </Router>
      <Floater />
    </MainContainer>
  );
}

export default App;
