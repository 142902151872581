import Loadable from "react-loadable";
import Loading from "./components/Loading";

export const HomeLoader = Loadable({
  loader: () => import("./pages/Home"),
  loading: Loading,
});

export const MenuItemsLoader = Loadable({
  loader: () => import("./components/MenuItems"),
  loading: Loading,
});

export const HomeServiceLoader = Loadable({
  loader: () => import("./containers/Home/Services"),
  loading: Loading,
});

export const HomeMethodologiesLoader = Loadable({
  loader: () => import("./containers/Home/Methodology"),
  loading: Loading,
});

export const HomeWhyChooseUsLoader = Loadable({
  loader: () => import("./containers/Home/WhyChooseUs"),
  loading: Loading,
});

export const HomeOurClientLoader = Loadable({
  loader: () => import("./containers/Home/OurClient"),
  loading: Loading,
});

export const FooterLoader = Loadable({
  loader: () => import("./components/Footer"),
  loading: Loading,
});

export const SideMenuLoader = Loadable({
  loader: () => import("./components/SideMenu"),
  loading: Loading,
});

export const ContactLoader = Loadable({
  loader: () => import("./pages/Contact"),
  loading: Loading,
});

export const NewsLetterLoadable = Loadable({
  loader: () => import("./containers/Home/NewsLetter"),
  loading: Loading,
});

export const AboutLoader = Loadable({
  loader: () => import("./pages/AboutUs"),
  loading: Loading,
});

export const ServiceLoader = Loadable({
  loader: () => import("./pages/Service"),
  loading: Loading,
});

export const ModalLoader = Loadable({
  loader: () => import("./components/Modal"),
  loading: Loading,
});

export const AnalysisFormLoader = Loadable({
  loader: () => import("./components/AnalysisForm"),
  loading: Loading,
});

export const SMSLoader = Loadable({
  loader: () => import("./pages/Sms"),
  loading: Loading,
});

export const TrainingLoader = Loadable({
  loader: () => import("./pages/Training"),
  loading: Loading,
});

export const TestLoader = Loadable({
  loader: () => import("./pages/Test"),
  loading: Loading,
});

export const EmployeeLoader = Loadable({
  loader: () => import("./pages/Employee"),
  loading: Loading,
});

export const AddUpdateCourseLoader = Loadable({
  loader: () => import("./pages/AddUpdateCourse"),
  loading: Loading,
});

export const BlogsListLoadableLoader = Loadable({
  loader: () => import("./pages/BlogsList"),
  loading: Loading,
});
