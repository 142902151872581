import { SET_CONTACT_PAGE } from "../common/action-names";

export const contact = {
  contact: null,
};

export function contactReducer(state = contact, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_CONTACT_PAGE:
      return {
        ...state,
        contact: { ...payload },
      };
    default:
      return state;
  }
}
