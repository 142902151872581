/* eslint-disable camelcase */
import React, { useReducer, createContext } from "react";
import { about, aboutReducer } from "../reducers/about";
import { common, commonReducer } from "../reducers/common";
import { contact, contactReducer } from "../reducers/contact";
import { home, homeReducer } from "../reducers/home";
import { services, serviceReducer } from "../reducers/service";
import { training, trainingReducer } from "../reducers/training";

export const Store = createContext();

const initialState = {
  common: common,
  contact: { ...contact },
  about: { ...about },
  services: { ...services },
  home: { ...home },
  training: { ...training },
};

const reduceReducers =
  (...reducers) =>
  (prevState, value, ...args) => {
    return reducers.reduce(
      (newState, reducer) => reducer(newState, value, ...args),
      prevState
    );
  };

const reducers = reduceReducers(
  commonReducer,
  contactReducer,
  aboutReducer,
  serviceReducer,
  homeReducer,
  trainingReducer
);

export function StoreProvider({ children, initState = initialState }) {
  const [state, dispatch] = useReducer(reducers, initState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{children}</Store.Provider>;
}

// ([^\s]+):
