import {
  SET_ABOUT_PAGE,
  SET_HOME_CHOOSE_US,
  SET_HOME_CLIENT,
  SET_HOME_FOOTER,
  SET_HOME_MAIN,
  SET_HOME_METHOD,
  SET_HOME_SERVICE,
} from "../common/action-names";
import { fetchAboutPageDetails } from "../services/about";
import {
  fetchHomeBanner,
  fetchHomeChooseUs,
  fetchHomeClient,
  fetchHomeFooter,
  fetchHomeMethodology,
  fetchHomeServices,
} from "../services/home";

export const setHomeBanner = (payload, dispatch) => {
  dispatch({
    type: SET_HOME_MAIN,
    payload,
  });
};

export const setHomeServices = (payload, dispatch) => {
  dispatch({
    type: SET_HOME_SERVICE,
    payload,
  });
};

export const setHomeClient = (payload, dispatch) => {
  dispatch({
    type: SET_HOME_CLIENT,
    payload,
  });
};

export const setHomeChooseUs = (payload, dispatch) => {
  dispatch({
    type: SET_HOME_CHOOSE_US,
    payload,
  });
};

export const setHomeMethod = (payload, dispatch) => {
  dispatch({
    type: SET_HOME_METHOD,
    payload,
  });
};

export const setHomeFooter = (payload, dispatch) => {
  dispatch({
    type: SET_HOME_FOOTER,
    payload,
  });
};

export const getHomeBanner = async (dispatch) => {
  try {
    const payload = await fetchHomeBanner();
    setHomeBanner(payload, dispatch);
  } catch (e) {
    console.log("error", e);
  }
};

export const getHomeServices = async (dispatch) => {
  try {
    const payload = await fetchHomeServices();
    setHomeServices(payload, dispatch);
  } catch (e) {
    console.log("error", e);
  }
};

export const getHomeClient = async (dispatch) => {
  try {
    const payload = await fetchHomeClient();
    setHomeClient(payload, dispatch);
  } catch (e) {
    console.log("error", e);
  }
};

export const getHomeChooseUs = async (dispatch) => {
  try {
    const payload = await fetchHomeChooseUs();
    setHomeChooseUs(payload, dispatch);
  } catch (e) {
    console.log("error", e);
  }
};

export const getHomeFooter = async (dispatch) => {
  try {
    const payload = await fetchHomeFooter();
    setHomeFooter(payload, dispatch);
  } catch (e) {
    console.log("error", e);
  }
};

export const getHomeMethod = async (dispatch) => {
  try {
    const payload = await fetchHomeMethodology();
    setHomeMethod(payload, dispatch);
  } catch (e) {
    console.log("error", e);
  }
};
