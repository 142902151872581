import axios from "axios";
import { getCookie, getMeta } from "../common/utils";
// const BASE_URL = "https://api.swifsys.com/pub(lic";
const BASE_URL = window.location.origin.includes("localhost")
  ? "https://www.swifsys.com/proxy.php"
  : "/proxy.php";

const SMS_BASE_URL = "https://www.swifsys.com/api/sms";

const toUrlEncoded = (obj) =>
  Object.keys(obj)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]))
    .join("&");

// export const fetchRequest = (url, method, isLocal, body) => {
//   const reqBody = body ? toUrlEncoded(body) : "";
//   return new Promise((resolve, reject) => {
//     const config = {
//       headers: {
//         "X-CSRF-TOKEN": "eykajflkasdjflksdfjl",
//       },
//     };
//     axios[method](`${isLocal ? "" : BASE_URL}${url}`, reqBody, config)
//       .then((res) => {
//         if (res.status == 200) {
//           console.log(res.data);
//           return resolve(res.data);
//         } else {
//           return reject(res.data);
//         }
//       })
//       .catch((err) => {
//         console.error("error to fetch api---", err);
//         return reject(err);
//       });
//   });
// };

const jsonToUrlEncoded = (json) => {
  const formBody = [];
  for (var property in json) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(json[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
};
export const fetchRequest = (
  uri,
  method,
  isLocal,
  body,
  isAuth,
  isSMS,
  header = {}
) => {
  const reqBody = body ? toUrlEncoded(body) : "";
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        "X-CSRF-TOKEN": getMeta("csrf-token"),
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        ...header,
      },
    };
    if (isAuth) {
      config.headers.Authorization = `Bearer ${getCookie("token")}`;
    }
    const requestBody = {
      method,
      uri,
      body: reqBody,
    };

    const axiosRes = isSMS
      ? axios[method.toString().toLowerCase()](`${SMS_BASE_URL}${uri}`, body, {
          headers: {
            "X-CSRF-TOKEN": getMeta("csrf-token"),
            "Content-Type": "application/json",
            ...header,
          },
        })
      : axios["post"](BASE_URL, jsonToUrlEncoded(requestBody), config);
    axiosRes
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          return resolve(res.data);
        } else {
          return reject(res.data);
        }
      })
      .catch((err) => {
        console.error("error to fetch api---", err);
        return reject(err);
      });
  });
};
