import styled from "@emotion/styled";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import logoWhite from "../assets/images/logo-white.png";
import menuIcon from "../assets/images/icons/menu.svg";
import { MenuItemsLoader, SideMenuLoader } from "../Loadable";
import route from "../common/route";
import { keyframes } from "@emotion/react";
const forwards = keyframes`
  from, 100%, to {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;
const Container = styled.header`
  padding: 10px 50px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-between;
  background: #bf0d3e;
  box-shadow: rgb(17 31 93 / 8%) 3px 0px 30px, rgb(27 27 43 / 9%) 2px 0px 5px;
  color: white;
  z-index: 5;
  @media screen and (max-width: 900px) {
    padding: 10px 16px;
    justify-content: center;
  }
`;
const MenuContainer = styled(Container)`
  @media screen and (max-width: 900px) {
    position: sticky;
    width: calc(100% - 32px);
    top: 0;
    justify-content: flex-start;
  }
`;
const LogoServiceContainer = styled(Container)`
  padding: 0px 50px;
  background: #bf0d3e;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  border-top: 1px solid #880025;
  position: sticky;
  top: 0;
  height: 39px;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
const Logo = styled.img`
  width: 32px;
  width: 30px;
  margin-right: 10px;
  animation: ${forwards} 5s linear infinite;
`;
const LogoText = styled.h1`
  font-family: "poiretone";
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.3px;
  font-size: 24px;
  position: relative;
  @media screen and (max-width: 900px) {
    justify-content: center;
    margin-left: 20px;
  }
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
  color: white;
  display: none;
`;
const MenuIcon = styled(Icon)`
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
  }
`;
const CallUs = styled.a`
  text-decoration: none;
  color: white;
  font-weight: 500;
  display: ${(props) => (props.mobile ? "none" : "block")};
  @media screen and (max-width: 900px) {
    display: ${(props) => (props.mobile ? "block" : "none")};
  }
`;
const SMSGateway = styled.a`
  text-decoration: none;
  color: white;
  font-weight: 500;
  display: inline-block;
  padding: 5px 20px;
  background: #be0d3e;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0px black;
`;
const RequetQuote = styled.button``;
const ChristmasCap = styled.img`
  position: absolute;
  width: 50px;
  z-index: 1;
  top: -15px;
  right: -30px;
`;
const Header = () => {
  const history = useHistory();
  const menuRef = useRef(null);
  const [isSideMenu, setIsSideMenu] = useState(false);
  const onMenuClick = () => {
    setIsSideMenu(true);
  };
  const onClose = () => {
    setIsSideMenu(false);
  };
  return (
    <>
      <SideMenuLoader open={isSideMenu} onClose={onClose} />
      <MenuContainer>
        <MenuIcon src={menuIcon} onClick={onMenuClick} />
        <LogoText onClick={() => history.push(route.base)}>
          {/* <ChristmasCap src="/christmascap.png" /> */}
          <Logo src={logoWhite} />
          Swifsys
        </LogoText>
        {/* <CallUs relative href="tel:+917022488224">
          +91-7022488224
        </CallUs>
        <CallUs mobile href="tel:+917022488224">
          Call Us
        </CallUs> */}
        {/* <SMSGateway href="/sms">SMS Gateway</SMSGateway> */}
      </MenuContainer>
      <LogoServiceContainer>
        <MenuItemsLoader />
      </LogoServiceContainer>
    </>
  );
};

export default Header;
