import tech from "../assets/images/Technology 2.png"
import innovation from "../assets/images/Innovation.png"
import monetization from "../assets/images/Monetization.png"
import effictiveness from "../assets/images/Effictiveness.png"
import banner from "../assets/images/services/Pamphlet.png"


export default {
  head: "Why Choose Us",
  img: banner,
  list: [
    {
      icon: tech,
      head: "Technology",
      desc: "We develop proprietary technologies to provide the unltimate in digital marketing",
      url: "",
    },
    {
      icon: innovation,
      head: "Innovation",
      desc: "We transform creative ideas and new original solutions for sites and brands",
      url: "",
    },
    {
      icon: monetization,
      head: "Monetization",
      desc: "We provide new ways for site to earn money",
      url: "",
    },
    {
      icon: effictiveness,
      head: "Effectiveness",
      desc: "Our platforms generate affective interactions between users and brands",
      url: "",
    },
  ],
};
