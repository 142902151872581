import etc from "../assets/images/client/etc.png"
import stand1 from "../assets/images/client/stand-1-armory.png"
import news from "../assets/images/client/the-express-now.png"
import cci from "../assets/images/client/clear-choice-imaging.png"
import dataSecurus from "../assets/images/client/data-securus.png"
import footnotes from "../assets/images/client/footnotes.png"
import hustlerKiChai from "../assets/images/client/hustler-ki-chai.png"
import inasa from "../assets/images/client/inasa.png"
import dental from "../assets/images/client/kosmic-dental-clinic.png"
import marketPrinceps from "../assets/images/client/market-princeps.png"
import orionPhysicalTherapy from "../assets/images/client/orion-physical-therapy.png"
import perfectClick from "../assets/images/client/perfect-click.png"
import viewsTube from "../assets/images/client/views-tube.png"
import vijaystudio from "../assets/images/client/vijaystudio.png"
// import inasa from "../assets/images/client/inasa.png"




export default {
  bg: "https://www.swifsys.com/proxy-img.php/home/1622978183_illstration.png",
  title: "Our Clients",
  list: [
    // {
    //   logo: "https://www.swifsys.com/proxy-img.php/logo/1622979270_abc.png",
    //   // url: "http://abcedupro.com/",
    //   name: "ABC",
    // },
    {
      logo: etc,
      name: "ETC",
    },
    {
      logo: news,
      name: "News",
    },
    {
      logo: stand1,
      // url: "https://stand1armory.com/",
      name: "Stand1",
    },
    // {
    //   logo: "https://www.swifsys.com/proxy-img.php/logo/1622979270_brd.png",
    //   // url: "http://brdefense.com/",
    //   name: "BRD",
    // },
    {
      logo: cci,
      // url: "https://clearchoic1dev.wpengine.com/",
      name: "clearchoice",
    },
    // {
    //   logo: "https://www.swifsys.com/proxy-img.php/logo/1622979270_car.png",
    //   name: "Car Service Wala",
    // },
    {
      logo: dataSecurus,
      // url: "http://datasecurus.com/",
      name: "Data Securus",
    },
    {
      logo: footnotes,
      name: "Foot notes",
    },
    // {
    //   logo: "https://www.swifsys.com/proxy-img.php/logo/the-tech-journal-logo.png",
    //   name: "The Tech Journal",
    //   // url: "https://thetechjournal.in/",
    // },
    {
      logo: viewsTube,
      name: "Views Tube",
      // url: "https://viewstube.in/",
    },
    // {
    //   logo: "https://www.swifsys.com/proxy-img.php/logo/magic-hands.png",
    //   name: "Magic Hands",
    //   // url: "http://magichands.co/",
    // },
    // {
    //   logo: "https://www.swifsys.com/proxy-img.php/logo/krypto-kafe-logo.png",
    //   name: "Krypto Kafe",
    //   // url: "http://kryptokafe.com/",
    // },
    {
      logo: inasa,
      name: "Inasa Computers",
      // url: "http://inasa.in/",
    },
    {
      logo: hustlerKiChai,
      name: "hustler Ki Chai",
      // url: "",
    },
    {
      logo: dental,
      name: "Kosmic Dental Clinic",
      // url: "",
    },
    {
      logo: marketPrinceps,
      name: "Market Princeps",
      // url: "",
    },
    {
      logo: orionPhysicalTherapy,
      name: "orion Physical Therapy",
      // url: "",
    },
    {
      logo: perfectClick,
      name: "perfect Click",
      // url: "",
    },
    {
      logo: vijaystudio,
      name: "vijay studio",
      // url: "",
    },
  ],
};
