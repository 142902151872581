import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import colors from "../common/colors";

const Container = styled.div`
  position: fixed;
  display: flex;
  top: 20%;
  right: 0;
  width: 40px;
  background: ${colors.primary};
  z-index: 1000;
  flex-direction: column;
  box-shadow: rgb(17 31 93 / 40%) 3px 0px 30px, rgb(27 27 43 / 20%) 2px 0px 5px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transition: visibility 0s, opacity 0.5s linear;
  @media screen and (max-width: 600px) {
    top: unset;
    bottom: 0;
    right: unset;
    flex-direction: row;
    width: 100%;
    height: 45px;
    border-radius: 0;
    align-items: center;
  }
`;
const IcontContainer = styled.a`
  padding: 7px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  @media screen and (max-width: 600px) {
    margin: 0;
  }
`;
const Icon = styled.img`
  width: 100%;
  @media screen and (max-width: 600px) {
    width: auto;
    height: 25px;
  }
`;
const Floater = () => {
  const [showFloater, setShowFloater] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > window.innerHeight / 1.5) {
      setShowFloater(true);
    } else {
      setShowFloater(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Container show={showFloater}>
      <IcontContainer href="tel:9598175864" target="_blank">
        <Icon src="/images/phone.png" />
      </IcontContainer>
      <IcontContainer
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=917011791800&text=${"Hi, I want to contact you."}`}
      >
        <Icon src="/images/whatsapp.png" />
      </IcontContainer>
      <IcontContainer target="_blank" href={`http://m.me/swifsys?ref=website`}>
        <Icon src="/images/messenger.png" />
      </IcontContainer>
      <IcontContainer target="_blank" href="mailto:support@swifsys.com">
        <Icon src="/images/mail.png" />
      </IcontContainer>
    </Container>
  );
};

export default Floater;
