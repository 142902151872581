import method from "../common/method";
import footer from "../data/footer";
import homeBanner from "../data/home-banner";
import homeChooseUs from "../data/home-choose-us";
import homeClient from "../data/home-client";
import homeMethodology from "../data/home-methodology";
import homeService from "../data/home-service";
import { fetchRequest } from "./common";

export const fetchHomeBanner = () => {
  // return fetchRequest(`/home-banner`, method.GET);
  return homeBanner;
};

export const fetchHomeServices = () => {
  // return fetchRequest(`/home-service`, method.GET);
  return homeService;
};

export const fetchHomeClient = () => {
  // return fetchRequest(`/home-client`, method.GET);
  return homeClient;
};

export const fetchHomeChooseUs = () => {
  // return fetchRequest(`/home-choose-us`, method.GET);
  return homeChooseUs;
};

export const fetchHomeMethodology = () => {
  // return fetchRequest(`/home-methodology`, method.GET);
  return homeMethodology;
};

export const fetchHomeFooter = () => {
  // return fetchRequest(`/footer`, method.GET);
  return footer;
};
