import { SET_TRAINING_PAGE } from "../common/action-names";

export const training = {
  training: {
    id: "web-development-training",
    head: "Full Stack Web Development Training (1st July 2021)",
    subHead: "Online Training (2 Hours / Day)",
    list: [
      {
        head: "Unit 1: Week 1 - Week 4",
        desc: [
          {
            head: "Introduction to Internet & Web Programming",
          },
          {
            head: "Introduction to HTML",
            list: [
              "Introduction HTML",
              "HTML Basics",
              "HTML Elements",
              "HTML5 Semantic",
              "HTML Attributes",
              "HTML Headings",
              "HTML Paragraph",
              "HTML Styles",
              "HTML Formatting",
              "HTML Quotations",
              "HTML Computer Code",
              "HTML Comments & Colours",
              "HTML CSS, Links and Images",
              "HTML Lists",
              "HTML Blocks",
              "HTML Classes",
              "HTML Layout",
              "HTML Responsive",
              "HTML iframes",
              "HTML JavaScript",
              "HTML Head",
              "HTML Entities and URI Code",
              "HTML Symbols and XHTML",
              "HTML Charset and Forms",
            ],
          },
          {
            head: "Introduction to CSS",
            list: [
              "Introduction CSS3",
              "CSS3 Syntax",
              "CSS3 Colours",
              "CSS3 Backgrounds",
              "CSS3 Borders",
              "CSS Margins",
              "CSS Padding",
              "CSS Height/Width",
              "CSS Box Model",
              "CSS Outline",
              "CSS Text",
              "CSS Fonts",
              "CSS Icons",
              "CSS Links",
              "CSS Lists",
              "CSS Tables",
              "CSS Display",
              "CSS Max-width",
              "CSS Position",
              "CSS Overflow",
              "CSS Float",
              "CSS Inline-block",
              "CSS Align",
              "CSS Combinators",
              "CSS Pseudo-class",
              "CSS Pseudo-element",
              "CSS Opacity",
              "CSS Navigation Bar",
              "Navbar",
              "Vertical Navbar",
              "Horizontal Navbar",
              "CSS Dropdowns",
              "CSS Image Gallery",
              "CSS Image Sprites",
              "CSS Attr Selectors",
              "CSS Forms",
              "CSS Counters",
              "CSS Website Layout",
              "CSS Units",
              "CSS Specificity",
              "CSS !important",
              "CSS Rounded Corners",
              "CSS Border Images",
              "CSS Backgrounds",
              "CSS Colors",
              "CSS Color Keywords",
              "CSS Gradients",
              "CSS Shadows",
              "CSS Text Effects",
              "CSS Web Fonts",
              "CSS 2D Transforms",
              "CSS 3D Transforms",
              "CSS Transitions",
              "CSS Animations",
              "CSS Tooltips",
              "CSS Style Images",
              "CSS Image Reflection",
              "CSS object-fit",
              "CSS object-position",
              "CSS Buttons",
              "CSS Pagination",
              "CSS Multiple Columns",
              "CSS User Interface",
              "CSS Variables",
              "CSS Box Sizing",
              "CSS Media Queries",
              "CSS MQ Examples",
              "CSS Flexbox",
              "SCSS",
              "Emotion Styling",
            ],
          },
          {
            head: "Exams & 1 Live Project",
            list: ["HTML", "CSS"],
          },
        ],
      },
      {
        head: "Unit 2: Week 5 - Week 8",
        desc: [
          {
            head: "Introduction to Javascript",
            list: [
              "JS Introduction",
              "Java Script Language Basics",
              "JavaScript Objects",
              "JavaScript Scope",
              "JavaScript Events",
              "JavaScript Strings",
              "JavaScript Numbers",
              "JavaScript Math",
              "JavaScript Arrays",
              " JavaScript Boolean",
              "JavaScript Comparisons",
              "JavaScript Conditions",
              "JavaScript Switch",
              "JavaScript Loops",
              " JavaScript Type Conversion",
              "JavaScript RegExp",
              "JavaScript Errors",
              "JavaScript Debugging",
              "JavaScript Hoisting",
              " JavaScript Strict Mode",
              "JavaScript Functions",
              "JavaScript Objects",
              "JavaScript Forms",
              "JavaScript HTML DOM",
              "JavaScript BOM",
              "ES6",
            ],
          },
          {
            head: "Exams & 1 Live Project",
            list: ["HTML", "CSS", "Javasccript", "ES6"],
          },
        ],
      },
      {
        head: "Unit 3: Week 9 - Week 12",
        desc: [
          {
            head: "Introduction to PHP",
            list: [
              "PHP Intro",
              "PHP Install",
              "PHP Syntax",
              "PHP Comments",
              "PHP Variables",
              "PHP Data Types",
              "PHP Strings",
              "PHP Numbers",
              "PHP Constants",
              "PHP Operators",
              "PHP Conditions",
              "PHP Loops",
              "PHP Functions",
              "PHP Arrays",
              "PHP RegEx",
              "PHP Form Validation",
              "PHP Date and Time",
              "PHP File Handling",
              "PHP Cookies",
              "PHP Sessions",
              "PHP JSON",
              "PHP Exceptions",
              "PHP What is OOP",
              "PHP Classes/Objects",
              "PHP Access Modifiers",
              "PHP Inheritance",
              "PHP Api Creation",
              "Request & Response",
              "Request Methods",
            ],
          },
          {
            head: "Introduction to MySQL",
            list: [
              "MySQL Intro",
              "MySQL RDBMS",
              "MySQL Create DB",
              "MySQL Drop DB",
              "MySQL Create Table",
              "MySQL Drop Table",
              "MySQL Alter Table",
              "MySQL Constraints",
              "MySQL Primary Key",
              "MySQL Foreign Key",
              "MySQL Auto Increment",
              "MySQL Data Types",
              "MySQL Functions",
              "MySQL SELECT",
              "MySQL WHERE",
              "MySQL AND, OR, NOT",
              "MySQL ORDER BY",
              "MySQL INSERT INTO",
              "MySQL NULL Values",
              "MySQL UPDATE",
              "MySQL DELETE",
              "MySQL LIMIT",
              "MySQL MIN and MAX",
              "MySQL COUNT, AVG, SUM",
              "MySQL LIKE",
              "MySQL Wildcards",
              "MySQL IN",
              "MySQL BETWEEN",
              "MySQL Aliases",
              "MySQL Joins",
              "MySQL INNER JOIN",
              "MySQL LEFT JOIN",
              "MySQL RIGHT JOIN",
              "MySQL CROSS JOIN",
              "MySQL Self Join",
              "MySQL UNION",
              "MySQL GROUP BY",
              "MySQL HAVING",
              "MySQL EXISTS",
              "MySQL ANY, ALL",
              "MySQL INSERT SELECT",
              "MySQL CASE",
              "MySQL Null Functions",
              "MySQL Operators",
            ],
          },
          {
            head: "Connectivity of MySQL to PHP",
            list: [
              "Connection",
              "Create DB",
              "Create Table",
              "Insert Data",
              "Get Last ID",
              "Insert Multiple",
              "Prepared",
              "Select Data",
              "Where",
              "Order By",
              "Delete Data",
              "Update Data",
              "Limit Data",
            ],
          },
          {
            head: "Exams & 1 Live Project",
            list: ["HTML", "CSS", "Javasccript", "ES6", "PHP", "MySQL"],
          },
        ],
      },
      {
        head: "Unit 4: Week 13 - Week 16",
        desc: [
          {
            head: "Node JS",
            list: [
              "Node JS Intro",
              "Node JS Modules",
              "Node JS HTTP Module",
              "Node JS Services",
              "HTTP Methods",
              "Node JS File System",
              "Node JS URL Module",
              "Node JS NPM",
              "Node JS Events",
              "Node JS Upload Files",
              "Node JS Email",
              "MySQL Connectivity",
              "Mongo DB Connectivity",
              "Node JS Middleware",
              "Authentication",
            ],
          },
          {
            head: "React JS",
            list: [
              "React Intro",
              "React Get Started",
              "React Installation",
              "ES6",
              "React Render HTML",
              "React JSX",
              "React Components",
              "React Props",
              "React State",
              "React Lifecycle",
              "React Events",
              "React Forms",
              "React CSS",
              "React Sass",
            ],
          },
          {
            head: "Exams & 1 Live Project",
            list: [
              "HTML",
              "CSS",
              "Javasccript",
              "ES6",
              "MySQL",
              "React JS",
              "Node JS",
            ],
          },
        ],
      },
      {
        head: "Unit 5: Week 17 - Week 20",
        desc: [
          {
            head: "Introduction to Laravel API",
            list: [
              "Overview",
              "Installation",
              "Routing",
              "Middleware",
              "Namespaces",
              "Controllers",
              "Controllers",
              "Request",
              "Cookies",
              "Request",
              "Response",
              "Validation",
              "Error Handling",
              "Sending Email",
              "Third Party API integration",
              "Event Handling",
              "Encryption",
              "Connectivity to MySQL",
            ],
          },
          {
            head: "Domain & Hosting Management",
            list: [
              "Domain Management",
              "Domain Transfer",
              "Hosting Management",
              "A record",
              "Domain Name Server",
              "NameServer",
              "Domain to Hosting Link",
            ],
          },
          {
            head: "Exams & 1 Live Project",
            list: [
              "HTML",
              "CSS",
              "Javasccript",
              "ES6",
              "MySQL",
              "React JS",
              "Node JS",
              "Deployment",
            ],
          },
        ],
      },
      {
        head: "Unit 6: Week 21 - Week 24",
        desc: [
          {
            head: "Major Live Project",
            list: [
              "HTML",
              "CSS",
              "React JS",
              "Node JS",
              "PHP",
              "MySQL",
              "Mongo DB",
              "Javascript",
              "Deployment",
            ],
          },
          {
            head: "Final Exam",
            list: [
              "HTML",
              "CSS",
              "React JS",
              "Node JS",
              "PHP",
              "MySQL",
              "Mongo DB",
              "Javascript",
              "Deployment",
            ],
          },
        ],
      },
    ],
  },
};

export function trainingReducer(state = training, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TRAINING_PAGE:
      return {
        ...state,
        training: { ...training, ...payload },
      };
    default:
      return state;
  }
}
