import assessment from "../assets/images/Assessment.png"
import audience from "../assets/images/AUDIENCE-UNDERSTANDING.png"
import ckm from "../assets/images/CRAFTING-KEY-MESSAGES.png"
import bm from "../assets/images/BUDGET-&-MEASUREMENT.png"

export default {
  label: "Our Methodologies",
  bg: "https://www.swifsys.com/proxy-img.php/home/1622978183_methodology.jpg",
  list: [
    {
      head: "Assessment",
      desc: "We conduct a thorough check of your brand’s current PR initiatives and engagement with media. Determining strengths and weaknesses thereafter, we chalk out a definitive PR scope of work for your brand.",
      img: assessment,
    },
    {
      head: "AUDIENCE UNDERSTANDING",
      desc: "After gaining a comprehensive understanding of the target market segment, we, as a PR firm engage with the media and digital platforms that can ably reach out to the desired audience with your key communications.",
      img: audience,
    },
    {
      head: "CRAFTING KEY MESSAGES",
      desc: "We help you create an impact through consistently-emitted news and well-crafted content that reinforce your market position and reiterate the value your business brings to its clientele.",
      img: ckm,
    },
    {
      head: "BUDGET & MEASUREMENT",
      desc: "After estimating the cost of carrying out various processes, we determine a mutually-agreed and transparent scale of measurement for assessing results.",
      img: bm,
    },
  ],
};
