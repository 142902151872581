import { SET_CURRENT_LANGUAGES, SET_LANGUAGES } from "../common/action-names";

export const common = {
  languages: null,
  currentLanguage: localStorage.lang ? localStorage.lang : "en",
};

export function commonReducer(state = common, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LANGUAGES:
      return {
        ...state,
        common: {
          ...state.common,
          languages: { ...payload },
        },
      };
    case SET_CURRENT_LANGUAGES:
      return {
        ...state,
        common: {
          ...state.common,
          currentLanguage: payload,
        },
      };
    default:
      return state;
  }
}
