import fbIcon from "../assets/images/social-icon/Facebook.png"
import instaIcon from "../assets/images/social-icon/Instagram.png"
import linkdinIcon from "../assets/images/social-icon/LinkedIn.png"
import twitterIcon from "../assets/images/social-icon/Twitter.png"
import wpIcon from "../assets/images/social-icon/What's app.png"
import pintIcon from "../assets/images/social-icon/Pinterest.png"



export default {
  copyright:
    "© 2021 Swifsys Technologies Pvt Ltd ALL RIGHT RESERVED",
  list: [
    {
      head: "Industries",
      list: [
        { name: "Small Business", url: "/service/small-business" },
        { name: "B2B", url: "/service/b2b" },
        { name: "Franchise", url: "/service/franchise" },
        { name: "Health Care", url: "/service/health-care" },
        { name: "Hospitality", url: "/service/hospitality" },
        { name: "Real State", url: "/service/real-state" },
        {
          name: "School / College Managment",
          url: "/service/school-college-management",
        },
        { name: "E-Commerce", url: "/service/e-commerce" },
        { name: "Sms Gateway", url: "/sms" },
      ],
    },
    {
      head: "Services",
      list: [
        { name: "SEO", url: "/service/seo" },
        { name: "PPC", url: "/service/ppc" },
        { name: "Social Media", url: "/service/social-media" },
        { name: "Development", url: "/service/development" },
        { name: "Content Creation", url: "/service/content-creation" },
        { name: "Visual Designing", url: "/service/visual-designing" },
      ],
    },
    {
      head: "About",
      list: [
        { name: "About Us", url: "/about-us" },
        { name: "Our Team", url: "/our-team" },
        { name: "Testimonial", url: "/testinomial" },
        { name: "FAQs", url: "/faq" },
        { name: "Contact Us", url: "/contact-us" },
      ],
    },
    {
      head: "Reach US",
      list: [
        { name: "Lucknow", url: "" },
        { name: "(+91) 7022488224", url: "+917022488224", type: "call" },
        { name: "(+91) 9598175864", url: "+919598175864", type: "call" },
        {
          name: "support@swifsys.com",
          url: "support@swifsys.com",
          type: "mail",
        },
      ],
    },
  ],
  socials: [
    {
      name: "Facebook",
      icon: fbIcon,
      url: "http://www.facebook.com/Swifsys-Technologies-106839418285516",
    },
    {
      name: "Instagram",
      icon: instaIcon,
      url: "http://www.instagram.com/swifsys/",
    },
    {
      name: "Pinterest",
      icon: pintIcon,
      url: "http://in.pinterest.com/swifsys/_saved/",
    },
    {
      name: "Twitter",
      icon: twitterIcon,
      url: "http://twitter.com/swifsys",
    },
    {
      name: "Linkedin",
      icon: linkdinIcon,
      url: "http://www.linkedin.com/company/swifsys/about/?viewAsMember=true",
    },
  ],
};
