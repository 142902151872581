const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  gray: "#717171",
  primary: "#bf0d3e",
  borderGray: "#d2d2d2",
  secondaryBg: "#f1f1f1",
  menuActiveBg: "#101010",
  menuLine: "#525252;",
  grayText: "#5a5a5a",
  red: "#ff0000",
  error: "#ff0000",
  success: "#007d00",
  menuHover: "#880025",
  post: "#ffcb54",
  get: "#54ff91",
};
export default colors;
