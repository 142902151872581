export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_CURRENT_LANGUAGES = "SET_CURRENT_LANGUAGES";

export const SET_CONTACT_PAGE = "SET_CONTACT_PAGE";

export const SET_ABOUT_PAGE = "SET_ABOUT_PAGE";

export const SET_SERVICE_PAGE = "SET_SERVICE_PAGE";

export const SET_TRAINING_PAGE = "SET_TRAINING_PAGE";

export const SET_HOME_MAIN = "SET_HOME_MAIN";
export const SET_HOME_SERVICE = "SET_HOME_SERVICE";
export const SET_HOME_METHOD = "SET_HOME_METHOD";
export const SET_HOME_CHOOSE_US = "SET_HOME_CHOOSE_US";
export const SET_HOME_CLIENT = "SET_HOME_CLIENT";
export const SET_HOME_FOOTER = "SET_HOME_FOOTER";
export const SET_HOME_BANNER = "SET_HOME_BANNER";
