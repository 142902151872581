import method from "../common/method";
import contact from "../data/contact";
import { fetchRequest } from "./common";

export const fetchContactPageDetails = () => {
  // return fetchRequest(`/contact`, method.GET);
  return contact;
};

export const submitContactQuery = (reqBody) => {
  return fetchRequest(`/send_mail.php`, method.POST, false, reqBody);
};

export const submitNewsletter = (reqBody) => {
  return fetchRequest(`/newsletter.php`, method.POST, false, reqBody);
};
