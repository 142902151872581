import contactTop from "../assets/images/contact-us-top.png"
import contactBottom from "../assets/images/contact-us-bottom.png"


export default {
  head: "Contact Us",
  desc: "Look no further! If you need VALUE/ELITE Digital Marketing and software solutions for your business!<br><br>We hold a passion for innovation, creative ideas, and the execution that brings them all together in one beautiful experience.",
  ringer: {
    top: contactTop,
    bottom:
      contactBottom,
  },
  contact: {
    label: "Our Office",
    list: [
      {
        key: "Phone",
        value: [
          {
            label: "+91-7011791800",
            href: "tel:+917011791800",
          },
          {
            label: "+91-9598175864",
            href: "tel:+919598175864",
          },
        ],
      },
      {
        key: "Email",
        value: [
          {
            label: "support@swifsys.com",
            href: "mail:support@swifsys.com",
          },
        ],
      },
      {
        key: "Address",
        value: [
          {
            label:
              "645 B/301 Abhishek Pura, Jankipuram Extension Lucknow UP 226022",
          },
        ],
      },
      
    ],
  },
  address:
    "First Floor, B-84 Vimal nagar, Kamta, Lucknow (UP) -226028, 645 B/301 Abhishek Pura, Jankipuram Extension Lucknow UP 226022",
  map: { lat: 26.8861571, lng: 81.0169425 },
  serviceLabel: "In what services have you interested?",
  send: "Send",
  services: [
    "SEO",
    "PPC",
    "Social Media",
    "Web Development",
    "App Development",
    "Content Creation",
    "Visual Designing",
    "Other",
  ],
};
