import { SET_ABOUT_PAGE } from "../common/action-names";
import { fetchAboutPageDetails } from "../services/about";

export const setAboutDetails = (payload, dispatch) => {
  dispatch({
    type: SET_ABOUT_PAGE,
    payload,
  });
};

export const getAboutDetails = async (dispatch) => {
  try {
    const about = await fetchAboutPageDetails();
    setAboutDetails(about, dispatch);
  } catch (e) {
    console.log("error", e);
  }
};
