import seoIcon from "../assets/images/SEO.png"
import ppcIcon from "../assets/images/PPC.png"
import smIcon from "../assets/images/Social-Media.png"
import saIcon from "../assets/images/sharing-about.png"
import sbIcon from "../assets/images/sharing-banner.png"
import devIcon from "../assets/images/Development.png"
import ccIcon from "../assets/images/Content-Creation.png"
import vdIcon from "../assets/images/Visual-Designing.png"



export default {
  head: "Swifsys Technologies",
  subHead: "One Stop For Digital Marketing and Software Solutions.",
  desc: "Here at Swifsys Technologies we meld creative ideas and technologies to help your business grow. We believe the superiority in digital marketing planning and implementation are the only steps to succeed in today's ferocious competition. Our experts serve up unique social media campaigns, website designs, SEO improvements, and more at affordable prices. <br /><br />In search of Identity? Join us today and there is never a dearth of opportunities in your world.",
  sHead: "What We Do",
  sList: [
    {
      img: seoIcon,
      name: "SEO",
      label: "SEO",
      tagLine: "Design concepts to make your trademark succeed online.",
      href: "/service/seo",
      det: [
        {
          name: "Free SEO Analysis",
          isForm: true,
          bg: "https://www.swifsys.com/proxy-img.php/services/1623009071_SEO.png",
        },
        {
          name: "SEO Services",
          href: "/service/seo-services",
        },
        {
          name: "Local SEO",
          href: "/service/local-seo",
        },
        {
          name: "Link Building Services",
          href: "/service/link-building-services",
        },
        {
          name: "Specialized SEO services",
          href: "/service/specialised-seo-services",
          det: [
            {
              label: "Video SEO",
              href: "/service/video-seo-service",
            },
            {
              label: "Mobile SEO",
              href: "/service/mobile-seo-service",
            },
            {
              label: "Link Removal Services",
              href: "/service/link-removal-service",
            },
            {
              label: "Comprehensive SEO Audit",
              href: "/service/comprehensive-seo-audit",
            },
            {
              label: "Search Engine Growth Hacking",
              href: "/service/search-engine-growth-hacking",
            },
          ],
        },
      ],
    },
    {
      img: ppcIcon,
      name: "PPC",
      label: "PPC",
      tagLine:
        "Target users with precise data using social media and be a hit.",
      href: "/service/ppc",
      det: [
        {
          name: "Free PPC Analysis",
          isForm: true,
          bg: "https://www.swifsys.com/proxy-img.php/services/1623009071_PPC.png",
        },
        {
          name: "PPC Management Services",
          href: "/service/ppc-management-services",
          det: [
            {
              label: "Google Ads Managment",
              href: "/service/ppc-management-services/google-ads-management",
            },
            {
              label: "Inmobi Ads",
              href: "/service/ppc-management-services/inmobi-ads",
            },
            {
              label: "Facebook Advertising",
              href: "/service/ppc-management-services/facebook-advertising",
            },
            {
              label: "Instagram Advertising",
              href: "/service/ppc-management-services/instagram-advertising",
            },
            {
              label: "LinkedIn Advertising",
              href: "/service/ppc-management-services/linkedin-advertising",
            },
            {
              label: "YouTube Advertising",
              href: "/service/ppc-management-services/youtube-advertising",
            },
            {
              label: "Yahoo Advertising",
              href: "/service/ppc-management-services/yahoo-advertising",
            },
            {
              label: "Bing Advertising",
              href: "/service/ppc-management-services/bing-advertising",
            },
            {
              label: "Quora Advertising",
              href: "/service/ppc-management-services/quora-advertising",
            },
            {
              label: "Reddit Advertising",
              href: "/service/ppc-management-services/reddit-advertising",
            },
          ],
        },
        {
          name: "Remarketing",
          href: "/service/remarketing",
        },
        {
          name: "Specialized PPC services",
          href: "/service/specialized-ppc-services",
          det: [
            {
              label: "Display Advertising",
              href: "/service/display-advertising",
            },
            {
              label: "Comprehensive PPC Campaign Audit",
              href: "/service/comprensite-ppc-campaign-audit",
            },
          ],
        },
      ],
    },
    {
      img: smIcon,
      name: "Social Media",
      tagLine: "To reach great heights with minimum investment. ",
      href: "/service/social-media",
      det: [
        {
          name: "Free Social Media Analysis",
          isForm: true,
          bg: "https://www.swifsys.com/proxy-img.php/services/1623009071_Social Media.png",
        },
        {
          name: "Social Media Optimization Service",
          href: "/service/social-media-optimization-service",
          det: [
            {
              label: "Facebook Management",
              href: "/service/facebook-management",
            },
            {
              label: "Instagram Management",
              href: "/service/instagram-management",
            },
            {
              label: "LinkedIn Management",
              href: "/service/linkedin-management",
            },
            {
              label: "Twitter Management",
              href: "/service/twitter-management",
            },
            {
              label: "YouTube Management",
              href: "/service/youtube-management",
            },
            {
              label: "Pinterest Management",
              href: "/service/pinterest-management",
            },
          ],
        },
        {
          name: "Specialized SMO Services",
          href: "/service/specialized-smo-services",
        },
        {
          name: "Social Media Growth Hacking",
          href: "/service/social-media-growth-hacking",
        },
      ],
    },
    {
      img: devIcon,
      name: "Development",
      tagLine: "Crafting cutting-edge websites that demand attention",
      href: "/service/development",
      det: [
        {
          name: "Free Website Analysis",
          isForm: true,
          bg: "https://www.swifsys.com/proxy-img.php/services/1623009071_Development.png",
        },
        {
          name: "Web Development Service",
          href: "/service/web-development-service",
          det: [
            {
              label: "Wordpress Development",
              href: "/service/wordpres-development",
            },
            {
              label: "Shopify Development",
              href: "/service/shopify-development",
            },
            {
              label: "React JS",
              href: "/service/react-js",
            },
            {
              label: "Angular JS",
              href: "/service/angular-js",
            },
            {
              label: "Next JS",
              href: "/service/next-js",
            },
            {
              label: "PHP",
              href: "/service/php",
            },
            {
              label: "Node JS",
              href: "/service/node-js",
            },
            {
              label: "Express JS",
              href: "/service/express-js",
            },
            {
              label: "HTML & CSS",
              href: "/service/html-and-css",
            },
            {
              label: "Laravel",
              href: "/service/laravel",
            },
            {
              label: "Code Igniter",
              href: "/service/code-igniter",
            },
          ],
        },
        {
          name: "Web Designing Service",
          href: "/service/web-designing-service",
        },
        {
          name: "Mobile App Development",
          href: "/service/mobile-app-development",
          det: [
            {
              label: "iPhone App Development",
              href: "/service/iphone-app-development",
            },
            {
              label: "Android App Development",
              href: "/service/android-app-development",
            },
          ],
        },
        {
          name: "E-Commerce Development",
          href: "/service/ecommerce-development",
        },
        {
          name: "Software Testing",
          href: "/service/software-testing",
        },
        {
          name: "Web Security",
          href: "/service/web-security",
        },
        {
          name: "Web Hosting",
          href: "/service/web-hosting",
          hidden: true,
        },
        {
          name: "Domain & SSL Registration",
          href: "/service/domain-and-ssl-registration",
          hidden: true,
        },
        {
          name: "API Creation / Integration",
          href: "/service/api-creation-integration",
          hidden: true,
        },
        {
          name: "CRM & ERP",
          href: "/service/crm-and-erp",
          hidden: true,
        },
        {
          name: "Website Maintenance",
          href: "/service/website-maintainance",
        },
        {
          name: "Website Speed Optimization",
          href: "/service/website-speed-optimization",
          hidden: true,
        },
      ],
    },
    {
      img: ccIcon,
      name: "Content Creation",
      tagLine: "Establish your brand with devoted creative thinking.",
      href: "/service/content-creation",
      det: [
        {
          name: "Copywriting",
          href: "/service/copywriting",
        },
        {
          name: "SEO Content Writing",
          href: "/service/seo-content-writing",
        },
        {
          name: "Blog Writing",
          href: "/service/blog-writing",
        },
        {
          name: "Promotional Writing",
          href: "/service/promotional-writing",
        },
      ],
    },
    {
      img: vdIcon,
      name: "Visual Designing",
      tagLine: "Art of providing meaningful and relevant experience to users",
      href: "/service/visual-designing",
      det: [
        {
          name: "Logo Designing",
          href: "/service/logo-designing",
        },
        {
          name: "Video Editing",
          href: "/service/video-editing",
        },
        {
          name: "Banner Designing",
          href: "/service/banner-designing",
        },
        {
          name: "Pamphlet",
          href: "/service/pamphlet",
        },
        {
          name: "Brochure",
          href: "/service/brochure",
        },
        {
          name: "Graphic Desgining",
          href: "/service/graphic-designing",
        },
      ],
    },
    {
      hidden: true,
      label: "Training",
      href: "/training",
      det: [
        {
          label: "Web Development Training",
          href: "/training/web-development-training",
        },
        {
          label: "Web Development Test",
          href: "/test/web-development",
          target: "_blank",
        },
      ],
    },
    {
      label: "Email Marketing",
      hidden: true,
      href: "/service/email-marketing",
    },
    {
      label: "About",
      hidden: true,
      det: [
        {
          label: "About Us",
          href: "/about-us",
        },
        {
          label: "FAQs",
          href: "/service/faq",
        },
        {
          label: "Location",
          href: "/service/location",
        },
        {
          label: "Contact Us",
          href: "/contact-us",
        },
      ],
    },
  ],
};
