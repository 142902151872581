export default {
  base: "/",
  home: "/home",
  contactUs: "/contact-us",
  aboutUs: "/about-us",
  service: "/service/:serviceId/:subServiceId?",
  sms: "/sms",
  test: "/test/web-development",
  employee: "/dashboard",
  addUpdateCourse: "/dashboard/add-update-course",
  blogsList: "/dashboard/blogs",
  courses: "/dashboard/courses",
  training: "/training/:trainingId?",
  noHeaderRoute: ["/test/web-development"],
  noFooterRoute: ["/test/web-development"],
};
