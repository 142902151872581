import { SET_SERVICE_PAGE } from "../common/action-names";

export const services = {
  services: {},
};

export function serviceReducer(state = services, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SERVICE_PAGE:
      return {
        ...state,
        services: { ...services, ...payload },
      };
    default:
      return state;
  }
}
